import React, { useEffect, useState } from "react";
import Helmet from "react-helmet"
import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  ModalHeader,
  Progress
} from "reactstrap";

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import OnlineClientDropdown from "../CommonForBoth/TopbarDropdown/OnlineClientDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import classname from "classnames";

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import useToken, { bcrypt, logout, switchUserList, uInfo, useSwitchUser, uToken, hasRole } from "../../useToken";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../common/data/countries";

import { HostUrl, PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
import Loader from "react-loader";
import $ from "jquery"
const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);
  const [current_training, setCurrentTraining] = useState([]);
  const [current_count, setCurrentCount] = useState(0);
  const [final_training, setFinalTraining] = useState([]);
  const [show, setShow] = useState(false);
  const [header_tracking_code, setHeaderTrackingCode] = useState("");
  const [existing, setExisting] = useState([]);
  // const [assessment_id, setAssessmentId] = useState(0);
  const [isClient, setIsClient] = useState(true);
  const [modal, setModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [countryCode, setCountryCode] = useState("+1");
  const [groupId, setGroupId] = useState("");
  const [groups, setGroups] = useState([]);
  const [coachTraining, setCoachTraining] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [meta, setMeta] = useState(false);
  let history = useHistory();

  const switchUser = switchUserList();

  const { setToken } = useToken();
  const { setSwitchUser } = useSwitchUser();

  useEffect(() => {
    const pusher = new Pusher(PusherKey, {
      cluster: 'mt1'
    })
    const channel1 = pusher.subscribe('my-channel');
    channel1.bind('chat-event', function (data) {
      console.log(data, 'coach header')
      if (
        data.data.sent_to == uInfo().user_id
        && data.data.sub_type == 'video'
      ) {
        swal({
          title: 'Invitation for video chat',
          text: "Click join button to goes on the video link",
          icon: "info",
          buttons: true,
          dangerMode: true,
          buttons: ["Cancel", "Join"],
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = `${HostUrl}/chat/${data.data.user_id}/${data.data.type}`;
          }
        });
      }
    })

    return (() => {
      pusher.unsubscribe('my-channel')
      // pusher.unsubscribe('channel_name2')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // if (uInfo().is_proposal_accepted == 'No') {
    //   setTimeout(history.push("/settings/accept-proposal"), 4000);
    // }

    // if (uInfo().is_approved == 'Yes' && uInfo().is_proposal_accepted == 'Yes' && uInfo().is_training_completed == 0) {
    //   getOnboardTraining();

    // }
    // checkVerfication();
    // getGroups();
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const searchClient = (search) => {
    if (search != "") {
      fetch(`${ApiUrl}` + "get/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: search }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setExisting(response.data.records);
            setIsClient(response.data.records.length > 0 ? true : false);
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
      setIsClient(true);
    }
  };

  const getOnboardTraining = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/onboard/training", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //if (uInfo().user_id == 4) {
          setCoachTraining(response.data.content);
          setCurrentTraining(response.data.current_training);
          setFinalTraining(response.data.final_training);
          setMasterCategory(response.data.get_master_id);
          setModal(true);
          setLoaded(true);

          //}
        } else {
          setLoaded(true);

          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const getGroups = () => {
    fetch(`${ApiUrl}` + "groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.assessment_id, 'getGroups')
          //setAssessmentId(response.data.assessment_id);
          setGroups(response.data.records);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (event, values) => {
    // props.navigation.history.push("/client/list");

    fetch(`${ApiUrl}` + "edit/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {

        if (data.status === true) {
          let res = data.data;
          //console.log(res.client_id, 'client_id');
          props.navigation.history.push(
            "/lead/dialer/" +
            bcrypt(res.client_id) +
            "/" +
            bcrypt(res.coach_id)
          );
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCountry = (selectedCountry) => {
    setCountryCode(selectedCountry.code);
  };

  const handleGroup = (selectedGroup) => {
    setGroupId(selectedGroup.group_id);
  };

  const checkVerfication = () => {
    fetch(`${ApiUrl}` + "check/verification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (response.data.verification_status == 'N' || response.data.is_approved != 'Yes') {
            setTimeout(history.push("/verification/wizard"), 4000);
          }
          $('#scriptContainer').append(response.data.header_tracking_code);
          //setHeaderTrackingCode(response.data.header_tracking_code);
        } else {
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setMeta(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const markAsComplete = (val) => {
    setLoaded(false);
    let data = {
      user_id: uInfo().user_id,
      content_id: current_training.content_id,
      cat_id: current_training.cat_id,
      section_id: current_training.section_id,
    }
    // if (!current_training.user_id) {
    fetch(`${ApiUrl}` + "content/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoaded(true);
        if (response.status === true) {
          if (val == 'default') {
            if (current_training.user_id) {
              console.log(current_count)
              setCurrentTraining(coachTraining[current_count + 1]);
              setCurrentCount(current_count + 1);
            } else {
              getOnboardTraining();
            }
          }

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const finisTraining = () => {
    let data = {
      user_id: uInfo().user_id,
      cat_id: masterCategory.cat_id,
    }
    fetch(`${ApiUrl}` + "finish/welcome/training", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          markAsComplete('finish');
          let newtoken = {
            is_training_completed: 1,
          }
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));
          setModal(false);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  const popover = (
    <Popover id="popover-basic" className="client-popover">
      <Popover.Header as="h3">Add a Client</Popover.Header>
      <Popover.Body>
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="row">
            <div className="col-md-6 mb-3">
              <AvField
                name="first_name"
                label="First Name"
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <AvField
                name="last_name"
                label="Last Name"
                className="form-control"
              />
            </div>

            <div className="col-md-12 mb-3">
              <label className="control-label">Group</label>
              <div className="MainTZone UsageGroup">
                <ReactSelect
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.group_id}
                  value={groups.filter(({ group_id }) => group_id === groupId)}
                  isMulti={false}
                  options={groups}
                  classNamePrefix="select2-selection"
                  name="groups_id"
                  onChange={(e) => {
                    handleGroup(e);
                  }}
                />
              </div>
              <AvField required type="hidden" name="group_id" value={groupId} />
            </div>
            <div className="col-md-12 mb-3">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                type="email"
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="control-label">Country</label>
              <div className="MainTZone">
                <ReactSelect
                  getOptionLabel={(option) =>
                    option.label + " (" + option.code + ")"
                  }
                  getOptionValue={(option) => option.code}
                  value={countries.filter(({ code }) => code === countryCode)}
                  isMulti={false}
                  options={countries}
                  classNamePrefix="select2-selection"
                  name="country_id"
                  onChange={(e) => {
                    handleCountry(e);
                  }}
                />
              </div>
              <AvField
                type="hidden"
                name="country_code"
                value={countryCode}
              />
            </div>
            <div className="col-md-6 mb-3">
              <AvField
                name="mobile"
                label="Mobile"
                className="form-control"
                placeholder="Enter mobile without country code"
                type="number"
                required
                validate={{
                  // required: { value: true, errorMessage: 'This field is invalid' },
                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                }}
              />
            </div>

            <div className="col-md-6 mb-3">
              <a className="btn btn-outline-primary" href="/lead/add">
                Advanced
              </a>
            </div>

            <div className="col-md-6 mb-3">
              <button
                className="btn btn-primary waves-effect waves-light pull-right"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </AvForm>
      </Popover.Body>
    </Popover>
  );
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        logout();
        history.push("/login");
        // if (data.status === true) {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        // } else {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UserToggle = (user_id) => {

    fetch(`${ApiUrl}` + 'login/choice', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'user_id': user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        if (res.status === true) {
          setToken(res.data.user);
          setSwitchUser(res.data.users);
          history.push('/');
          // toast.success(res.message, { theme: "colored" });
        } else {
          toast.error(res.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  return (
    <React.Fragment>
      {/* <Helmet><script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=745c95b2-741c-4c90-a4ba-a7b29b9d25f3"> </script></Helmet> */}
      <div className="tm-embed-container" id="scriptContainer"></div>
      <div className="dialer">
        <Loader loaded={loaded}></Loader>
      </div>
      <div className="navbar-header">
        <Container fluid className="px-0">

          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box d-lg-none">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="50" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightSmallPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>
                        <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            {"Dashboard"}
                          </Link>
                        </li>

                        {hasRole(uInfo(), ["clients"]) && (
                          <li className="nav-item dropdown">
                            <Link to="/lead/list" className="nav-link dropdown-toggle arrow-none">
                              {"Contacts"}{" "}
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left  d-none",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["inbox"]) && uInfo().user_id == '4' && (
                                <Link to={"/chat/inbox/" + bcrypt(0) + "/" + bcrypt(0)}
                                  className="dropdown-item">
                                  {"Inbox"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["leads"]) && (
                                <Link to="/lead/list" className="dropdown-item d-none">
                                  {"Leads"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["clients"]) && (
                                <Link to="/client/list" className="dropdown-item">
                                  {"Clients"}
                                </Link>
                              )}
                         

                              {
                                hasRole(uInfo(), ["my_coaches"]) && uInfo().has_coach_license == 'Yes' && (
                                  <Link to="/users/coach" className="dropdown-item d-none">
                                    {"My Coaches"}
                                  </Link>
                                )
                              }

                              {hasRole(uInfo(), ["groups_and_tags"]) && (
                                <Link to="/clients/groups" className="dropdown-item d-none">
                                  {"Groups & Tags"}
                                </Link>
                              )}
                            

                              {hasRole(uInfo(), ["custom_fields"]) && (
                                <Link to="/clients/field/list" className="dropdown-item d-none">
                                  {"Custom Fields"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["sales"]) && (
                          <li className="nav-item dropdown d-none">
                            <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                              {"Sales"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["pipeline"]) && (
                                <Link to="/pipeline/list" className="dropdown-item">
                                  {"Pipeline"}
                                </Link>
                              )}
                              {
                                hasRole(uInfo(), ["onboard_training"]) && uInfo().has_coach_license == 'Yes' && (
                                  <Link to="/training/status/list" className="dropdown-item">
                                    {"OnBoard Training"}
                                  </Link>
                                )
                              }

                              {/* <Link to="/tasks/list" className="dropdown-item">
                              {"Tasks"}
                            </Link> */}

                              {/* {hasRole(uInfo(), ["contracts"]) && (
                                <Link to="/contract/list" className="dropdown-item">
                                  {"Contracts"}
                                </Link>
                              )} */}


                              {hasRole(uInfo(), ["invoices"]) && (
                                <Link to="/invoice/list" className="dropdown-item">
                                  {"Invoices"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        
                        {hasRole(uInfo(), ["campaign"]) && (
                          <li className="nav-item dropdown d-none">
                            <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                              {"Campaign"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["campaign_list"]) && (
                                <Link to="/campaign/list" className="dropdown-item">
                                  {"Campaign"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["call_status"]) && (
                                <Link to="/callstatus/list" className="dropdown-item">
                                  {"Call Status"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["caller_group"]) && (
                                <Link to="/callergroup/list" className="dropdown-item">
                                  {"Caller Group"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["template_library"]) && (
                                <Link to="/emailtemplates/list" className="dropdown-item">
                                  {"Templates Library"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["unsubscribe_keywords"]) && (
                                <Link to="/unsubkeywords/list" className="dropdown-item">
                                  {"Unsubscribe Keywords"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["coaching"]) && (
                          <li className="nav-item dropdown d-none">
                            <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                              {"Coaching"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["assessment_list"]) && (
                                <Link to="/assessment/list/all" className="dropdown-item">
                                  {"Assessments"}
                                </Link>
                              )}
                              {/* <Link to="/assessment/list/center" className="dropdown-item">
                              {"Profit Center"}
                            </Link> */}
                              {/* {
                              assessment_id > 0 && (
                                <Link
                                  to={
                                    "/progress/tracker/" +
                                    bcrypt(assessment_id) +
                                    "/scheduling_freedom"
                                    }
                                  className="dropdown-item">
                                  {"Profit Center"}
                                </Link>
                              )
                            } */}

                              {/* {hasRole(uInfo(), ["waybook_training_admin"]) && ( */}
                              <Link to="/trainings" className="dropdown-item">
                                {"Trainings"} <i className="fas fa-arrow-up"></i>
                              </Link>

                              {hasRole(uInfo(), ["trainings"]) && (
                                <Link to="/coach/trainings" className="dropdown-item">
                                  {"Trainings"}
                                </Link>
                              )}

                              {hasRole(uInfo(), ["resources"]) && (
                                <Link to="/assesments/resources" className="dropdown-item">
                                  {"Resources"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["reports"]) && (
                          <li className="nav-item dropdown">
                            <Link to="/report/summary" className="nav-link dropdown-toggle arrow-none">
                              {"Reports"}{" "}
                           
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left d-none",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["reporting"]) && (
                                <Link to="/report/summary" className="dropdown-item">
                                  {"Reporting"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["credit_log"]) && (
                                <Link to="/report/credithistory" className="dropdown-item d-none">
                                  {"Credits Log"}
                                </Link>
                              )}

                            </div>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </nav>
                </div>


              </div>

            </div>
            <div className="float-end">

              {/* d-lg-inline-block */}
              <div className="app-search  d-none  search">
                <div className="position-relative d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => searchClient(e.target.value)}
                    onBlur={() =>
                      setTimeout(() => {
                        setExisting([]);
                      }, 1000)
                    }
                  />
                  <OverlayTrigger
                    rootClose={true}
                    trigger="click"
                    placement="bottom-start"
                    overlay={popover}
                  >
                    <button
                      onClick={() => setShow(!show)}
                      type="button"
                      className="btn"
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </OverlayTrigger>
                </div>
                <ul className="client-search-bar">
                  {!isClient && <li className="text-primary">No data found</li>}
                  {existing.map((row, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={
                            "/lead/dialer/" +
                            bcrypt(row.client_id) +
                            "/" +
                            bcrypt(row.coach_id)
                          }
                          target="_parent"
                        >
                          <div className="d-grid-template">
                            <div className="info">
                              <i className="fas fa-user"></i>
                              {row.first_name} {row.last_name}
                            </div>

                            <div className="info">
                              <i className="fas fa-mobile"></i>
                              {row.mobile}
                            </div>
                          </div>

                          <div className="d-grid-template">
                            <div className="info">
                              <i className="fas fa-user"></i> {row.email}
                            </div>

                            <div className="info">
                              <i className="fas fa-phone"></i>
                              {row.phone}
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* d-inline-block d-lg-non */}
              <div className="dropdown ms-2 SearchDropDown">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => setSearch(!isSearch)}
                >
                  <i className="mdi mdi-magnify" />
                </button>
                <div
                  className={
                    isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <div className="app-search d-inline-block search px-3">
                    <div className="position-relative d-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => searchClient(e.target.value)}
                        onBlur={() =>
                          setTimeout(() => {
                            setExisting([]);
                          }, 1000)
                        }
                      />
                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="bottom-start"
                        overlay={popover}
                      >
                        <button
                          onClick={() => setShow(!show)}
                          type="button"
                          className="btn"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>

                    <ul className="client-search-bar">
                      {!isClient && <li className="text-primary">No data found</li>}
                      {existing.map((row, i) => {
                        return (
                          <li key={i}>
                            <Link
                              to={
                                "/lead/dialer/" +
                                bcrypt(row.client_id) +
                                "/" +
                                bcrypt(row.coach_id)
                              }
                              target="_parent"
                            >
                              <div className="d-grid-template">
                                <div className="info">
                                  <i className="fas fa-user"></i>
                                  {row.first_name} {row.last_name}
                                </div>

                                <div className="info">
                                  <i className="fas fa-mobile"></i>
                                  {row.mobile}
                                </div>
                              </div>

                              <div className="d-grid-template">
                                <div className="info">
                                  <i className="fas fa-user"></i> {row.email}
                                </div>

                                <div className="info">
                                  <i className="fas fa-phone"></i>
                                  {row.phone}
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <Link
                to={"/video/room/host"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Group Video Chat"
              >
                <i className="ion ion-md-videocam"></i>
              </Link>


              <Link
                to={"/chat"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Incoming Chat Message"
              >
                <i className="ion ion-md-chatboxes"></i>
                {props.readCount > 0 && (
                  <span
                    className="badge bg-danger rounded-pill"
                    id={"total_read_count"}
                  >
                    {props.readCount}
                  </span>
                )}
              </Link>

              <OnlineClientDropdown />
              {/* d-lg-inline-block */}
              <div className="dropdown d-none  none">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen font-size-24"></i>
                </button>
              </div>






              <NotificationDropdown />

              <ProfileMenu />




            </div>
          </Col>
        </Container>

      </div >
      <Row>

        <Col sm={12}>
          <div className="top-navigation px-3">
            <Collapse
              isOpen={createmenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <span className="DashIcon"><i className="ti-dashboard"></i></span> <span>{"Dashboard"}</span>
                  </Link>
                </li>

                {hasRole(uInfo(), ["clients"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'clients' ? '' : 'clients')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      <span className="DashIcon"><i className="mdi mdi-account-box"></i></span> <span>{"Clients"}{" "}
                        <i className="mdi mdi-chevron-right"></i></span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'clients' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["inbox"]) && uInfo().user_id == '4' && (
                        <Link to={"/chat/inbox/" + bcrypt(0) + "/" + bcrypt(0)} className="dropdown-item">
                          {"Inbox"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["leads"]) && (
                        <Link to="/lead/list" className="dropdown-item">
                          {"Leads"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["clients"]) && (
                        <Link to="/client/list" className="dropdown-item">
                          {"Clients"}
                        </Link>
                      )}
                      {
                        hasRole(uInfo(), ["my_coaches"]) && uInfo().has_coach_license == 'Yes' && (
                          <Link to="/users/coach" className="dropdown-item">
                            {"My Coaches"}
                          </Link>
                        )
                      }
                      {hasRole(uInfo(), ["groups_and_tags"]) && (
                        <Link to="/clients/groups" className="dropdown-item">
                          {"Groups & Tags"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["custom_fields"]) && (
                        <Link to="/clients/field/list" className="dropdown-item">
                          {"Custom Fields"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["sales"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'sales' ? '' : 'sales')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Sales"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'sales' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["pipeline"]) && (
                        <Link to="/pipeline/list" className="dropdown-item">
                          {"Pipeline"}
                        </Link>
                      )}
                      {
                        hasRole(uInfo(), ["onboard_training"]) && uInfo().has_coach_license == 'Yes' && (
                          <Link to="/training/status/list" className="dropdown-item">
                            {"OnBoard Training"}
                          </Link>
                        )
                      }
                      {hasRole(uInfo(), ["contracts"]) && (
                        <Link to="/contract/list" className="dropdown-item">
                          {"Contracts"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["invoices"]) && (
                        <Link to="/invoice/list" className="dropdown-item">
                          {"Invoices"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["campaign"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'campaign' ? '' : 'campaign')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Campaign"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'campaign' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["campaign_list"]) && (
                        <Link to="/campaign/list" className="dropdown-item">
                          {"Campaign"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["call_status"]) && (
                        <Link to="/callstatus/list" className="dropdown-item">
                          {"Call Status"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["caller_group"]) && (
                        <Link to="/callergroup/list" className="dropdown-item">
                          {"Caller Group"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["template_library"]) && (
                        <Link to="/emailtemplates/list" className="dropdown-item">
                          {"Templates Library"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["unsubscribe_keywords"]) && (
                        <Link to="/unsubkeywords/list" className="dropdown-item">
                          {"Unsubscribe Keywords"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["coaching"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'coaching' ? '' : 'coaching')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Coaching"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'coaching' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["assessment_list"]) && (
                        <Link to="/assessment/list/all" className="dropdown-item">
                          {"Assessments"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["trainings"]) && (
                        <Link to="/coach/trainings" className="dropdown-item">
                          {"Trainings"}
                        </Link>
                      )}

                      {hasRole(uInfo(), ["resources"]) && (
                        <Link to="/assesments/resources" className="dropdown-item">
                          {"Resources"}
                        </Link>
                      )}

                    </div>
                  </li>
                )}
                {hasRole(uInfo(), ["reports"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'reports' ? '' : 'reports')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Reports"}{" "}
                      <i className="mdi mdi-chevron-down"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'reports' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["reporting"]) && (
                        <Link to="/report/summary" className="dropdown-item">
                          {"Reporting"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["credit_log"]) && (
                        <Link to="/report/credithistory" className="dropdown-item">
                          {"Credits Log"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>

          </div>
          <Modal
            isOpen={modal}
            fade={true}
            size="xl"
            className="survey-content-question PopUpModal"
            backdrop={'static'}
            centered={true}
            toggle={() => {
              setModal(false);
            }}
          >
            <Loader loaded={loaded}></Loader>
            <ModalBody>
              <div className="row">
                <div className={`welcome-training code-blue mb-4`}><h4 className="card-title">
                  {current_training.title ?? masterCategory.title}
                </h4></div>

                <Row className="mx-0">
                  <Col xl={6}>
                    <div className="logout-wizard-training">
                      {
                        switchUser.map((user, i) => {
                          if (uInfo().user_id != user.user_id && uInfo().staff_user_id == null) {

                            return (
                              <button
                                key={i}
                                onClick={() => UserToggle(user.user_id)}
                                className="btn btn-md w-100 text-left"
                              >
                                <i className="mdi mdi-account-switch font-size-17 text-muted align-middle me-1" />
                                <span>Switch To {user.type == "WlAdmin" ? "Admin" : user.type}</span>
                              </button>
                            )
                          }
                        })
                      }
                      <button type="button" className="btn btn-md btn-link" onClick={() => Logout()}>
                        <i className="fas fa-power-off" /> Logout
                      </button>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="text-align-right training-progress PopUpProgress">

                      <Progress color="primary" title={Math.round((masterCategory.total_user / masterCategory.total_content) * 100)}
                        value={Math.round((masterCategory.total_user / masterCategory.total_content) * 100)}>
                        {Math.round((masterCategory.total_user / masterCategory.total_content) * 100)}%<div></div></Progress>
                    </div>
                  </Col>
                </Row>
                <div className="PointsContent">
                  <Row>
                    <Col lg="3" className="pl-0">
                      <ul className="popupSideBar">

                        {coachTraining.map((row, i) => {
                          return (
                            <li key={i} disabled={i > 0 ? coachTraining[i - 1].user_id ? false : true : false} className={current_training.content_id == row.content_id && "mm-active"}>
                              <button onClick={() => { setCurrentTraining(row); setCurrentCount(i) }} className="dropdown-item mb-1">
                                <span> {row.title}</span>
                                {row.user_id && (
                                  <i className="fas fa-check pl-5"></i>
                                )}
                              </button>
                            </li>
                          )
                        })
                        }
                      </ul>
                    </Col>
                    <Col lg="9">
                      <Row>
                        <Col className="col-12">
                          <Card className="NoShadow HeightAuto PopUpDataShow">
                            <Row className="mx-0">
                              <Col xl={12}>
                                <Card
                                  className="NoShadow traning  VideoHeight"
                                  style={{ borderRight: 0 }}
                                >
                                  <CardBody>
                                    <div className="video-lesson" dangerouslySetInnerHTML={{ __html: current_training.content }} />
                                  </CardBody>

                                </Card>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {final_training.content_id == current_training.content_id ? (
                <Button
                  type="button"
                  color="primary"
                  onClick={() => { finisTraining(); }}
                >
                  Finish
                </Button>
              )
                : (
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => markAsComplete('default')}
                  >
                    Next
                  </Button>
                )}


            </ModalFooter>
          </Modal>
        </Col>

      </Row>


    </React.Fragment >
  );
};

export default Header;
