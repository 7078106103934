import React, { useEffect, useState } from "react";
import Helmet from "react-helmet"
import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  ModalHeader,
  Progress
} from "reactstrap";

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import OnlineClientDropdown from "../CommonForBoth/TopbarDropdown/OnlineClientDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import classname from "classnames";

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import useToken, { bcrypt, logout, switchUserList, uInfo, useSwitchUser, uToken, hasRole } from "../../useToken";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../common/data/countries";

import { HostUrl, PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
import Loader from "react-loader";
import $ from "jquery"
const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);
  const [current_training, setCurrentTraining] = useState([]);
  const [current_count, setCurrentCount] = useState(0);
  const [final_training, setFinalTraining] = useState([]);
  const [show, setShow] = useState(false);
  const [header_tracking_code, setHeaderTrackingCode] = useState("");
  const [existing, setExisting] = useState([]);
  // const [assessment_id, setAssessmentId] = useState(0);
  const [isClient, setIsClient] = useState(true);
  const [modal, setModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [countryCode, setCountryCode] = useState("+1");
  const [groupId, setGroupId] = useState("");
  const [groups, setGroups] = useState([]);
  const [coachTraining, setCoachTraining] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [meta, setMeta] = useState(false);
  let history = useHistory();

  const switchUser = switchUserList();

  const { setToken } = useToken();
  const { setSwitchUser } = useSwitchUser();

  useEffect(() => {
    const pusher = new Pusher(PusherKey, {
      cluster: 'mt1'
    })
    const channel1 = pusher.subscribe('my-channel');
    channel1.bind('chat-event', function (data) {
      console.log(data, 'coach header')
      if (
        data.data.sent_to == uInfo().user_id
        && data.data.sub_type == 'video'
      ) {
        swal({
          title: 'Invitation for video chat',
          text: "Click join button to goes on the video link",
          icon: "info",
          buttons: true,
          dangerMode: true,
          buttons: ["Cancel", "Join"],
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = `${HostUrl}/chat/${data.data.user_id}/${data.data.type}`;
          }
        });
      }
    })

    return (() => {
      pusher.unsubscribe('my-channel')
      // pusher.unsubscribe('channel_name2')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

  }, []);



  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        logout();
        history.push("/login");
        // if (data.status === true) {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        // } else {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

 
  return (
    <React.Fragment>
       <div className="tm-embed-container" id="scriptContainer"></div>
      <div className="dialer">
        <Loader loaded={loaded}></Loader>
      </div>
      <div className="navbar-header">
        <Container fluid className="px-0">

          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box d-lg-none">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="50" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightSmallPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>
                        <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            <i class="fa fa-home"></i>
                            {" "}{"dashboard"}
                          </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                             <i className="fa fa-list-ul"></i>
                              {" "}{"Lists"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                              <div
                                className={classname(
                                  "dropdown-menu dropdown-menu-left",
                                  {}
                                )}
                                >
                                {uInfo().display_contacts === 1 && (
                                  <Link to="/contact/list" className="dropdown-item">
                                    {"Contacts"}
                                  </Link>
                                )}
                                <Link to="/call/log" className="dropdown-item">
                                  {"Call Logs"}
                                </Link>
                              </div>
                        </li>
                        
                      </ul>
                    </Collapse>
                  </nav>
                </div>


              </div>

            </div>
            <div className="float-end">

             
            

              <ProfileMenu />




            </div>
          </Col>
        </Container>

      </div >
      <Row>

        <Col sm={12}>
          <div className="top-navigation px-3">
            <Collapse
              isOpen={createmenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                            <i class="fa fa-home"></i>
                            {" "}{"dashboard"}
                    </Link>
                </li>
                 <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'list' ? '' : 'list')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      <span className="DashIcon"> <i className="fa fa-list-ul"></i></span> <span>{"Lists"}{" "}
                        <i className="mdi mdi-chevron-right"></i></span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'list' ? 'show' : ''}`}
                    >
                     {uInfo().display_contacts === 1 && (
                      <Link to="/contact/list" className="dropdown-item">
                        {"Contacts"}
                      </Link>
                    )}
                    <Link to="/call/log" className="dropdown-item">
                      {"Call Logs"}
                    </Link>
                    
                    </div>
                  </li>
              </ul>
            </Collapse>

          </div>
        </Col>

      </Row>


    </React.Fragment >
  );
};

export default Header;
