import PropTypes from 'prop-types'
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import React, { useEffect, useState } from "react"
 

// Import Routes all
import { userRoutes, authRoutes, noHeaderRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

import VerticalLayout from "./components/VerticalLayout"
import HorizontalLayout from "./components/HorizontalLayout"
import CampaignLayout from "./components/HorizontalLayout/CampaignLayout"

import NonAuthLayout from "./components/NonAuthLayout"
import { Helmet } from 'react-helmet';

import "./assets/scss/theme.scss"
import "./assets/css/style.css" 
import "./assets/css/modern-style.css"
import 'react-toastify/dist/ReactToastify.css';
import Pages404 from "./pages/Extra Pages/pages-404";

import {
  ProductName,
  bgColor,
  btnPrimaryBgColor,
  btnPrimaryBgHoverColor,
  textColor,
  btnPrimaryTextColor,
  btnPrimaryHoverText,
  btnDangerTextColor,
  btnDangerBgColor,
  btnDangerHoverText,
  btnDangerBgHoverColor,
  btnInfoTextColor,
  btnInfoBgColor,
  btnInfoHoverText,
  btnInfoBgHoverColor,
  navTextColor,
  navTextHoverColor
} from './config.js'
import { uTheme } from './useToken'

const App = props => {

  const [meta, setMeta] = useState(false);

  useEffect(() => {
    setColor();
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setMeta(true);
    }, 1000);
  }, [!meta]);

  const Layout = VerticalLayout

  function setColor() {
    document.documentElement.style.setProperty('--bd-color', bgColor);
    document.documentElement.style.setProperty('--text-color', textColor);

    document.documentElement.style.setProperty('--btn-primary-text', btnPrimaryTextColor);
    document.documentElement.style.setProperty('--btn-primary', btnPrimaryBgColor);
    document.documentElement.style.setProperty('--btn-primary-hover-text', btnPrimaryHoverText);
    document.documentElement.style.setProperty('--btn-primary-hover', btnPrimaryBgHoverColor);

    document.documentElement.style.setProperty('--btn-danger-text', btnDangerTextColor);
    document.documentElement.style.setProperty('--btn-danger', btnDangerBgColor);
    document.documentElement.style.setProperty('--btn-danger-hover-text', btnDangerHoverText);
    document.documentElement.style.setProperty('--btn-danger-hover', btnDangerBgHoverColor);

    document.documentElement.style.setProperty('--btn-info-text', btnInfoTextColor);
    document.documentElement.style.setProperty('--btn-info', btnInfoBgColor);
    document.documentElement.style.setProperty('--btn-info-hover-text', btnInfoHoverText);
    document.documentElement.style.setProperty('--btn-info-hover', btnInfoBgHoverColor);

    document.documentElement.style.setProperty('--nav-text-color', navTextColor);
    document.documentElement.style.setProperty('--nav-hover-text-color', navTextHoverColor);
  }
  return (
    <React.Fragment>
      {
        meta && (
          <div className="application_route">
            <Helmet titleTemplate={`%s - ${ProductName}`} defaultTitle={ProductName}>
              <meta name="title" content={ProductName} />
            </Helmet>
          </div>
        )
      }
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {noHeaderRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={CampaignLayout}
              component={route.component}
              key={idx}
              requiredRoles={route.requiredRoles}
              isAuthProtected={true}
              themeUpdate={setColor()}
              exact
            />
          ))}
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              requiredRoles={route.requiredRoles}
              isAuthProtected={true}
              themeUpdate={setColor()}
              exact
            />
          ))}


        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App
