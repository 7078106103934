import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  Table,
  Button,
  Badge
} from "reactstrap"

import Helmet from "react-helmet"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Link, withRouter } from "react-router-dom"

import avatar from "../../assets/images/users/user-4.jpg"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Logs from "../Logs/Logs.js"
import { ApiUrl, ProductName } from "../../config"
import { toast , Flip} from "react-toastify"
import { dcrypt, uToken } from "../../useToken"
import LoginHistory from "../../components/tables/LoginHistory"
import Group from "../../pages/Coaches/Group/Group.js"



// actions

//Import Action to copy breadcrumb items from local state to redux state

const UserProfile = props => {

  const [page_name] = useState("Profile");
  const [idx] = useState(dcrypt(props.match.params.id));
  const [info, setInfo] = useState({});
  const [managerscolumn, setManagersColumn] = useState([]);
  const [managersrow, setManagersRow] = useState([]);
  const [loginHistory, setLoginHistory] = useState([]);
  const [loginHistoryColumn, setLoginHistoryColumn] = useState([]);
  const [historySubscriptionRows, setHistorySubscriptionRows] = useState([]);
  const [historySubscriptionColumn, setHistorySubscriptionColumn] = useState([]);
  useEffect(() => {
    console.log('UserProfile', props);
    getData();

  }, []);

  const getData = () => {

    fetch(`${ApiUrl}` + 'profile/user/' + idx, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, 'Data fetched by API');
          setInfo(response.data.info);
          setManagersColumn(response.data.managerscolumn);
          setManagersRow(response.data.managersrow);
          setLoginHistory(response.data.loginHistory);
          setLoginHistoryColumn(response.data.loginHistoryColumn);
          setHistorySubscriptionRows(response.data.historySubscriptionRows);
          setHistorySubscriptionColumn(response.data.historySubscriptionColumn);
          console.log(response, 'data')
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <React.Fragment>

      <Helmet>
        <title>Profile </title>
      </Helmet>
      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4>{page_name}</h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">{ProductName}</li>
              <li key={1} className="breadcrumb-item"><Link to="#">{page_name}</Link></li>
            </ol>
          </div>
        </Col>
        <Col sm={6}>
          <div className="page-title-box text-align-right">
            <Button
              type="button"
              color="secondary"
              onClick={() => props.history.goBack()}
            >
              Back{" "}
            </Button>
          </div>{" "}
        </Col>{" "}
      </Row>

      <Row>

        <Col md={6}>
          <Card>
            <CardBody>
              <CardTitle className="h4">User Info</CardTitle>
              <div className="table-responsive user-info">
                <Table className="table table-bordered mb-0">
                  <tbody>
                    <tr>
                      <td>UserId</td>
                      <td>{info.user_id}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{info.name}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{info.email}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{info.status}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>{info.user_created_at}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        {
          loginHistoryColumn.length > 0 && (
            <Col md={6}>
              <LoginHistory columns={loginHistoryColumn} records={loginHistory} tableTitle={`Login History`} />
            </Col>

          )
        }


      </Row>


      {historySubscriptionColumn.length > 0 && (
        <LoginHistory columns={historySubscriptionColumn} records={historySubscriptionRows} tableTitle={`Subscription History`} />
      )}

      {info.role_id == '3' && managerscolumn.length > 0 && (
        <LoginHistory columns={managerscolumn} records={managersrow} tableTitle={`Coach List`} />
      )}

      {/* {info.role_id == '3' && (
        <Row>
          <Col lg="12">
            <Group user_id={idx} />
          </Col>
        </Row>
      )} */}

      <Row>
        <Col lg="12">
          <Logs user_id={idx} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default UserProfile