import * as React from 'react';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
import swal from "sweetalert";
import ListGroup from 'react-bootstrap/ListGroup';

export default class WayTrainingAssign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: props.userId,
            trainingList: []
        }
    }

    componentDidMount() {
        const setInputState = (el, state) => {
            if (state === 'indeterminate') {
                el.indeterminate = true
            } else {
                el.indeterminate = false
                el.checked = state
            }
        }

        const updateOwned = (el) => {
            if (el.hasAttribute('data-children')) {
                let state = el.checked
                el.getAttribute('data-children').split(' ').forEach(id => {
                    let owned = document.getElementById(id)
                    setInputState(owned, state)
                    updateOwned(owned)
                })
            }
        }

        const updateOwner = (el) => {
            if (el.hasAttribute('data-parent')) {
                let owner = document.getElementById(el.getAttribute('data-parent'))
                let states = []
                let collectiveState
                owner.getAttribute('data-children').split(' ').every(id => {
                    let owned = document.getElementById(id)
                    let state = owned.indeterminate === true ? 'indeterminate' : owned.checked
                    if (states.length > 0 && states.indexOf(state) === -1) {
                        collectiveState = 'indeterminate'
                        return false
                    } else {
                        states.push(state)
                        return true
                    }
                })
                collectiveState = collectiveState || states[0]
                setInputState(owner, collectiveState)
                updateOwner(owner)
            }
        }

        document.querySelectorAll('.nested-multiselect').forEach(multiselect => {
            multiselect.querySelectorAll('input[type="checkbox"][data-children], input[type="checkbox"][data-parent]').forEach(input => {
                input.addEventListener('change', event => {
                    updateOwned(event.currentTarget)
                    updateOwner(event.currentTarget)
                })
            })
        });

        this.getWayUserTraining();
    }

    getWayUserTraining = () => {
        fetch(`${ApiUrl}` + "get/assign/waybook/category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ user_id: this.state.user_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        trainingList: response.data.record
                    });
                    const setInputState = (el, state) => {
                        if (state === 'indeterminate') {
                            el.indeterminate = true
                        } else {
                            el.indeterminate = false
                            el.checked = state
                        }
                    }

                    const updateOwned = (el) => {
                        if (el.hasAttribute('data-children')) {
                            let state = el.checked
                            el.getAttribute('data-children').split(' ').forEach(id => {
                                let owned = document.getElementById(id)
                                setInputState(owned, state)
                                updateOwned(owned)
                            })
                        }
                    }

                    const updateOwner = (el) => {

                        if (el.hasAttribute('data-parent')) {
                            let owner = document.getElementById(el.getAttribute('data-parent'))
                            let states = []
                            let collectiveState
                            owner.getAttribute('data-children').split(' ').every(id => {
                                let owned = document.getElementById(id)
                                let state = owned.indeterminate === true ? 'indeterminate' : owned.checked
                                if (states.length > 0 && states.indexOf(state) === -1) {
                                    collectiveState = 'indeterminate'
                                    return false
                                } else {
                                    states.push(state)
                                    return true
                                }
                            })
                            collectiveState = collectiveState || states[0]
                            setInputState(owner, collectiveState)
                            updateOwner(owner)
                        }
                    }

                    document.querySelectorAll('.nested-multiselect').forEach(multiselect => {
                        multiselect.querySelectorAll('input[type="checkbox"][data-children], input[type="checkbox"][data-parent]').forEach(input => {
                            input.addEventListener('change', event => {
                                updateOwned(event.currentTarget)
                                updateOwner(event.currentTarget)
                            })
                        })
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', this.state.user_id);
        fetch(`${ApiUrl}` + "assign/waybook/training", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: response.message,
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    render() {

        return (
            <div>
                <form method="post" onSubmit={this.handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">



                    <fieldset class="nested-multiselect-14">
                        {
                            this.state.trainingList.map((cat, i) => {
                                return (
                                    <fieldset class="nested-multiselect">
                                        <ListGroup>
                                            <ListGroup.Item>
                                                <label id={`label-${cat.cat_id}`}>
                                                    <input id={`${cat.cat_id}`}
                                                        type="checkbox"
                                                        name="categories"
                                                        value={`${cat.cat_id}`}
                                                        aria-owns={`subcategories-${cat.cat_id}`}
                                                        defaultChecked={cat.assign_checked}
                                                        data-children={cat.childeren} />
                                                    <span>{cat.title}</span>
                                                </label>
                                                <fieldset id={`subcategories-${cat.cat_id}`}
                                                    aria-label={`${cat.cat_id} subcategories`}>
                                                    <ListGroup>
                                                        {
                                                            cat.sub_cat.map((sub, s) => {
                                                                return (
                                                                    <ListGroup.Item>
                                                                        <label id={`label-${sub.cat_id}`}>
                                                                            <input id={`${sub.cat_id}`}
                                                                                type="checkbox"
                                                                                name="categories"
                                                                                value={`${sub.cat_id}`}
                                                                                aria-owns={`subcategories-${sub.cat_id}`}
                                                                                data-parent={cat.cat_id}
                                                                                defaultChecked={sub.assign_checked}
                                                                                data-children={sub.childeren} />
                                                                            <span>{sub.title}</span>
                                                                        </label>
                                                                        <fieldset id={`subcategories-${sub.cat_id}`}
                                                                            aria-label={`${sub.cat_id} subcategories`}
                                                                        >
                                                                            <ListGroup>
                                                                                {
                                                                                    sub.sub_cat.map((final, f) => {
                                                                                        return (
                                                                                            <ListGroup.Item>
                                                                                                <label id={`label-${final.cat_id}`}>
                                                                                                    <input id={`${final.cat_id}`}
                                                                                                        type="checkbox"
                                                                                                        name="subcategory[]"
                                                                                                        defaultChecked={final.assign_checked}
                                                                                                        value={`${cat.cat_id}-${sub.cat_id}-${final.cat_id}`}
                                                                                                        data-parent={sub.cat_id} />
                                                                                                    <span>{final.title}</span>
                                                                                                </label>
                                                                                            </ListGroup.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ListGroup>

                                                                        </fieldset>
                                                                    </ListGroup.Item>
                                                                )
                                                            })
                                                        }
                                                    </ListGroup>

                                                </fieldset>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </fieldset>
                                );

                            })
                        }
                    </fieldset>

                    {/* <fieldset class="nested-multiselect">
                        {
                            this.state.trainingList.map((cat, i) => {
                                return (
                                    <>
                                        <label id={`label-${cat.cat_id}`}>
                                            <h2>{cat.master_id}</h2>
                                            <input
                                                id={cat.cat_id}
                                                type="checkbox"
                                                name="categories"
                                                value={cat.cat_id}
                                                aria-owns={`subcategories-${cat.cat_id}`}
                                                data-parent="0"
                                                data-children={cat.childeren}
                                            />
                                            <span>{cat.title}</span>
                                        </label>
                                        <fieldset id={`subcategories-${cat.cat_id}`} aria-label={`${cat.cat_id} subcategories`}>
                                            {
                                                cat.sub_cat.map((training, t) => {
                                                    return (
                                                        <label id={`label-${training.cat_id}`}>
                                                            <input id={training.cat_id}
                                                                type="checkbox"
                                                                name="categories"
                                                                value={training.cat_id}
                                                                data-parent={`${cat.cat_id}`} />
                                                            <span>{training.title}</span>
                                                        </label>
                                                    )
                                                })
                                            }

                                        </fieldset>
                                    </>
                                );

                            })
                        }
                    </fieldset> */}

                    <button type="submit" className="btn btn-primary w-100" >Assign Training</button>
                </form>
            </div >
        );
    };
};