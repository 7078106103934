import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Alert,
    ModalBody,
    ModalFooter,
     Button,
} from "reactstrap";
import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { bcrypt, isValidJSONString, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';

const InitialPassword = (props) => {
    const [vData, setVData] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [msg, setMsg] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [type] = useState('email_verified');
    const [code_key] = useState('email_verify_code');
    
    let history = useHistory();

    const changeInitialPassword = (event, values) => {
        setLoaded(false);
        fetch(`${ApiUrl}` + 'change/initial/password', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === true) {
                   let newtoken = {
                        is_initial_password_change: 1,
                      };
                      const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                      };
                      localStorage.setItem("authUser", JSON.stringify(authUser));
                swal({
                    text: "Your initial password has been successfully changed. The new password has also been sent to your email",
                    icon: "success",
                })
                    .then((confirmation) => {
                        if (confirmation) {
                            history.push("/dashboard");
                      }
                });
                } else {
                    swal({
                        title: "Sorry...",
                        text: res.message,
                        button: "Ok",
                        icon: "warning",
                    });
                    setLoaded(true);
                }
            })
            .catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    
    const skipChange = () => {
        setLoaded(false);
        fetch(`${ApiUrl}` + 'keep/initial/password', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === true) {
                    setLoaded(true);
                    let newtoken = {
                        is_initial_password_change: 1,
                      };
                      const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                      };
                      localStorage.setItem("authUser", JSON.stringify(authUser));

                    history.push("/dashboard");
                } else {
                    swal({
                        title: "Sorry...",
                        text: res.message,
                        button: "Ok",
                        icon: "warning",
                    });
                    setLoaded(true);
                }
            })
            .catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    return (
        <React.Fragment>
            {msg && msg !== '' && (
                <Alert color="info">{msg}</Alert>
            )}
            <AvForm className="form-horizontal mt-4"
                onValidSubmit={(e, v) => changeInitialPassword(e, v)}>
                <ModalBody>
                    <div className="mb-3">
                        <AvField
                            name="plain_password"
                            label="Initial Password"
                            className="form-control"
                            placeholder="Initial Password"
                            defaultValue={uInfo().plain_password}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            name="password"
                            label="Change Initial Password"
                            className="form-control"
                            placeholder="Enter New Password"
                            required
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button 
                        disabled={!loaded} 
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit">
                        Changes Password
                    </button>
                   <Button
                      type="button"
                      color="success"
                      className="ml-5"
                       onClick={skipChange}
                    >
                      Skip - Retain Initial Password
                    </Button>
                 </ModalFooter>
            </AvForm>
        </React.Fragment>
    );
};

export default InitialPassword;
