import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

import Select from "react-select"
import countries from "../../../common/data/countries";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match ? this.props.match.params.id ? dcrypt(this.props.match.params.id) : this.props.id ? this.props.id : null : null,
      coach_id: this.props.match ? this.props.match.params.coach_id ? dcrypt(this.props.match.params.coach_id) : this.props.coach_id ? this.props.coach_id : null : null,
      page_name: this.props.match ? this.props.match.params.id ? "Edit Client" : "Create Client" : "",
      record: [],
      groups: [],
      managers: [],
      group_id: 2,
      country_code: 0,
      CustomField: [],
      customFieldData: {},
      from: this.props.from ? this.props.from : ''
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getGroups();
    this.getCustomField();
  }

  getCustomField = (role) => {
    fetch(`${ApiUrl}` + "get/custom/field", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            CustomField: JSON.parse(response.data.record.custom_fields),
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getGroups = (role) => {
    fetch(`${ApiUrl}` + "groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {

          this.setState({
            groups: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/company", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response, "data edit/add");
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            group_id: parseInt(response.data.record.group_id),
            country_code: response.data.record.country_code,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {


    values.custom_fields = JSON.stringify(this.state.customFieldData);

    if (this.state.from == 'clientDetails') {
      values.old_client_id = this.props.compannyId;
      values.from = 'clientDetails';
    }


    if (this.state.id) {
      values.id = this.state.record.client_id ? this.state.record.client_id : -1;
    }

    fetch(`${ApiUrl}` + "edit/company", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data, 'Editing');
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          
          if (this.state.from == 'clientDetails' || this.state.from == 'companyDetails') {
            this.props.getData();
          } else {
            setTimeout(
              () => this.props.history.push('/company/list'),
              1000
            );
          }
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCountry = (selectedCountry) => {
    this.setState({
      country_code: selectedCountry.code
    })
  }

  handleGroup = (selectedGroup) => {

    this.setState({ group_id: selectedGroup.group_id });

    // this.setState({
    //   group: selectedGroup.id
    // })
  }

  getFormElement = (elementIndex, elementSchema) => {
    let oldValue = this.state.record.custom_fields != undefined && this.state.record.custom_fields != '' ? JSON.parse(this.state.record.custom_fields) : '{}';
    const element = {
      key: elementSchema.key,
      name: elementSchema.key,
      label: elementSchema.label,
      options: elementSchema.options
    };

    if (!this.state.customFieldData.hasOwnProperty([element.name]) && oldValue != '{}') {
      this.setState(prevState => ({
        customFieldData: {
          ...prevState.customFieldData,
          [element.name]: oldValue[element.name]
        }
      }));
    }
    // return;


    if (elementSchema.type === "select") {
      return <div className="col-md-6 mb-3" key={elementIndex}>
        <div className="form-group">
          <label>{`${element.label}`}</label>
          <select className="select form-control"
            name={element.name}
            // value={el.type || 'textbox'}
            onChange={this.handleChange.bind(this, elementSchema.type)}
          >
            {
              element.options.map((row, i) => {
                return <option key={i} selected={this.state.customFieldData[element.name] == row.option ? 'selected' : ''} value={`${row.option}`}>{row.option}</option>
              })
            }
          </select>
        </div>

      </div>
    } else if (elementSchema.type === "radio") {
      return <div className="col-md-6 mb-3" key={elementIndex}>
        <label>{`${element.label}`}</label>
        <fieldset className="form-group">
          <div className="form-control border-0 p-0 h-auto ">
            {
              element.options.map((row, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input checked={this.state.customFieldData[element.name] == row.option ? 'checked' : ''}
                      onChange={this.handleChange.bind(this, elementSchema.type)} name={element.name} type="radio"
                      className="form-check-input" value={row.option} />
                    <label className="form-check-label">{`${row.option}`}</label>
                  </div>
                );
              })
            }
          </div>

        </fieldset>
      </div>
    } else if (elementSchema.type === "checkbox") {
      return <div className="col-md-6 mb-3" key={elementIndex}>
        <label>{`${element.label}`}</label>

        <fieldset name={`${element.name}`} className="form-group">
          <div className="form-control border-0 p-0 h-auto is-untouched is-pristine av-valid">
            {
              element.options.map((row, i) => {
                return (
                  <div key={i} className="form-check form-check-inline">
                    <input onChange={this.handleChange.bind(this, elementSchema.type)} name={`${element.name}`} type="checkbox"
                      className=" form-check-input" checked={this.state.customFieldData[element.name] && this.state.customFieldData[element.name].includes(row.option) ? 'checked' : ''} value={`${row.option}`} />
                    <label className="form-check-label">{`${row.option}`}</label>
                  </div>
                );
              })
            }
          </div>
        </fieldset>
      </div>
    } else if (elementSchema.type === "date") {
      return <div className="col-md-6 mb-3" key={elementIndex}>
        <div className="form-group">
          <label>{`${element.label}`}</label>
          <input name={element.name} type="date" value={this.state.customFieldData[element.name]}
            className="form-control" onChange={this.handleChange.bind(this, elementSchema.type)} />

        </div>
      </div>
      // return <TextField {...props} />
    } else {
      return <div className="col-md-6 mb-3" key={elementIndex}>
        <div className="form-group">
          <label>{`${element.label}`}</label>
          <input name={element.name} type="text" value={this.state.customFieldData[element.name]}
            className="form-control" onChange={this.handleChange.bind(this, elementSchema.type)} />

        </div>
      </div>
      // return <TextField {...props} />
    }
  }

  handleChange(type, e) {
    const { name, value } = e.target;

    if (type == 'checkbox') {
      if (this.state.customFieldData.hasOwnProperty([name])) {
        if (this.state.customFieldData[name].includes(value)) {
          this.setState(prevState => ({
            customFieldData: {
              ...prevState.customFieldData,
              [name]: this.state.customFieldData[name].filter(color => color !== value)
            }
          }))
        } else {
          this.setState(prevState => ({
            customFieldData: {
              ...prevState.customFieldData,
              [name]: [...prevState.customFieldData[name], value]
            }
          }))
        }
      } else {
        this.setState(prevState => ({
          customFieldData: {
            ...prevState.customFieldData,
            [name]: [value]
          }
        }));
      }

      // console.log(this.state.customFieldData)

    } else {
      // console.log(name, value)
      this.setState(prevState => ({
        customFieldData: {
          ...prevState.customFieldData,
          [name]: value
        }
      }));
    }


  }


  CreateCustomFieldUI() {
    return this.state.CustomField.map((el, i) => (
      this.getFormElement(i, el)
    ));
  }

  getAlert() {
    this.setState({
      id: this.props.id,
      coach_id: this.props.coach_id,
      from: this.props.from
    }, () => { this.getData() });
    // this.getData();
    // this.getGroups();
    // this.getCustomField();
    // console.log('getAlert company Child', this.props);
  }


  render() {
    // console.log(this.state)
    return (
      <React.Fragment>

        {
          this.state.from != 'clientDetails' && this.state.from != 'companyDetails' && (
            <>
              <Helmet>
                <title> {this.state.page_name} </title>
              </Helmet>
              <Row>
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4> {this.state.page_name} </h4>
                    <ol className="breadcrumb m-0">
                      <li key={0} className="breadcrumb-item active">
                        {ProductName}
                      </li>
                      <li key={1} className="breadcrumb-item">
                        <Link to="#"> {this.state.page_name} </Link>
                      </li>
                    </ol>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="page-title-box text-align-right">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      Back
                    </Button>
                  </div>
                </Col>
              </Row>
              <ToastContainer />
            </>
          )}
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                // model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="website"
                      value={(this.state.record.website) ? this.state.record.website : "https://"}
                      label="Company Domain"
                      className="form-control"
                      required
                      type="url"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="first_name"
                      value={this.state.record.first_name}
                      label="Company Name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="email"
                      value={this.state.record.email}
                      label="Email"
                      required
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="control-label">
                      Country
                    </label>
                    <div className="MainTZone AddCompany One">
                      <Select
                        getOptionLabel={option => option.label + ' (' + option.code + ')'}
                        getOptionValue={option => option.code}
                        value={countries.filter(({ code }) => code === this.state.country_code)}
                        isMulti={false}
                        options={countries}
                        classNamePrefix="select2-selection"
                        name="country_id"
                        onChange={(e) => {
                          this.handleCountry(e)
                        }}
                      />
                    </div>
                    <AvField type="hidden" name="country_code" value={this.state.country_code} />
                  </div>

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="phone"
                      placeholder="Enter phone without extension"
                      value={this.state.record.phone}
                      label="Phone Number"
                      className="form-control"
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="phone_extension"
                      value={this.state.record.phone_extension}
                      label="Phone Extension"
                      className="form-control"

                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="mobile"
                      value={this.state.record.mobile}
                      label="Mobile"
                      className="form-control"
                      placeholder="Enter mobile without country code"
                      validate={{
                        // required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>


                  <div className="col-md-6 mb-3">
                    <AvField
                      name="city"
                      value={this.state.record.city}
                      label="City"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="state"
                      value={this.state.record.state}
                      label="State"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="zip"
                      value={this.state.record.zip}
                      label="Zip"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="address"
                      value={this.state.record.address}
                      label="Address"
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="linkedIn"
                      value={this.state.record.linkedIn}
                      label="LinkedIn"
                      className="form-control"
                      type="url"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="title"
                      value={this.state.record.title}
                      label="Title"
                      className="form-control"
                    />
                  </div>
                  {
                    this.state.CustomField && this.state.CustomField.length > 0 && (
                      this.CreateCustomFieldUI()
                    )
                  }
                </ModalBody>
                <ModalFooter>
                  {this.state.from != 'clientDetails' && this.state.from != 'companyDetails' && (
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancel
                    </Button>
                  )}
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment >
    );
  }
}
