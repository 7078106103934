import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Container,
    Card,
    CardBody,
} from "reactstrap";


import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { bcrypt, isObjectEmpty, isValidJSONString, logout, uInfo, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import VerificationInitialPassword from "./InitialPassword.js";


const Verification = (props) => {
    const [vData, setVData] = useState({});
    const [isApproval, setIsApproval] = useState(false);
    let history = useHistory();

    useEffect(() => {
        checkVerfication();
    }, []);

    const checkVerfication = () => {
        if (uInfo().is_initial_password_change === 0) {
            setIsApproval(true);
        }
    };


    const Logout = () => {
        fetch(`${ApiUrl}` + "logout", {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${uToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                logout();
                history.push("/login");
                // if (data.status === true) {
                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                // } else {
                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                // }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    return (
        <React.Fragment>
            <Helmet>
                <title> Change Initial Password </title>
            </Helmet>

            <div className="account-pages my-5 pt-sm-5" >

                <Container >
                    <ToastContainer />

                    <Row className="justify-content-center" >

                        <Col md={12}
                            lg={12}
                            xl={12} >

                            <Card className="overflow-hidden" >
                                <CardBody className="pt-0" >
                                    <div className="logout-wizard mt-5 pull-right">
                                        <button type="button" className="btn btn-md btn-link" onClick={() => Logout()}>
                                            <i className="fas fa-power-off" /> Logout
                                        </button>
                                    </div>
                                    <h3 className="text-center mt-5 mb-4" >
                                        <Link to="#"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="45"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    {
                                        isApproval && (
                                            <VerificationInitialPassword />
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};

export default Verification;
